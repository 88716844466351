declare module 'o365.pwa.declaration.shared.dexie.objectStores.AppResourceState.d.ts' {
    export class AppResourceState {
        static objectStoreDexieSchema: string;

        appId: string;
        id: string;
        scope: string;
        relativeRoot: string;
        url: string;

        get primaryKey(): string;

        constructor(appId: string, id: string, scope: string, relativeRoot: string, url: string);

        save(): Promise<void>;
        delete(): Promise<void>;
        forceReload(): Promise<AppResourceState | null>;
    }
}

export {};
